import styled from 'styled-components'
import device from '../../utils/device-size'

export default styled.table`
  border: none;
  border-spacing: unset;
  width: 100%;
  font-size: 14px;
  color: white;
  text-align: left;

  @media only screen and (max-width: ${device.sizeS}) {
    table {
      font-size: 12px;
    }
  }
`
