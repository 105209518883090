import React from 'react'
import PropTypes from 'prop-types'

import ReuseTableStyled from './styled'

class ReuseTable extends React.PureComponent {
  render() {
    return <ReuseTableStyled className={this.props.className}>{this.props.children}</ReuseTableStyled>
  }
}

ReuseTable.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
}

export default ReuseTable
